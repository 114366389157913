import { useEffect, useState } from 'react';

import { Container, Typography } from '@mui/material';
import Masonry from 'react-masonry-css';

import RefCard from '../../components/RefCard';

const Resources = () => {
  const [resources, setResources] = useState([]);
  const [isSet, setIsSet] = useState(false);

  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1
  };
  
  useEffect(() => {
    fetch('https://japanglish-resources-api.herokuapp.com/api/resources')
      .then(res => res.json())
      .then(data => {
        setResources(data);
        setIsSet(true);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <main>
      <Container>
        <div>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ fontSize: "2em", marginBottom: "1em" }}
          >
            Resources
          </Typography>
          <Masonry
            container="true"
            breakpointCols={breakpoints}
            spacing={3}
            className="collections-masonry-grid"
            columnClassName="collections-masonry-grid-column"
          >
          {isSet ? resources.map((resource, i) => <RefCard key={i} resource={resource} />) : null}
          </Masonry>
        </div>
      </Container>
    </main>
  )
}

export default Resources;
