import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// import {ref, update } from 'firebase/database';
// import { db } from '../../utils/firebase';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { allAlphabet } from '../../utils/util.js';

const DataEntry = () => {
  const history = useHistory();

  // const updateData = (letter, wordName, imageUrl, wordType, kana, english, meaning, example, exampleTranslation) => {
  //   const updates = {};
  //   updates[`${letter}/${wordName}`] = {
  //     imageUrl: imageUrl,
  //     wordType: wordType,
  //     kana: kana,
  //     english: english,
  //     meaning: meaning,
  //     example: example,
  //     exampleTranslation: exampleTranslation
  //   };
  //   return update(ref(db), updates)
  //     .then(() => {
  //       console.log("Data saved successfully!")
  //     })
  //     .catch(err => console.log(err));
  // };
  

  const initialFormState = {
    letterCategory: '',
    wordName: '',
    imageUrl: '',
    wordType: '',
    kana: '',
    english: '',
    meaning: '',
    example: '',
    exampleTranslation: ''
  };

  const initialFormStateErr = {
    letterCategory: false,
    wordName: false,
    imageUrl: false,
    wordType: false,
    kana: false,
    english: false,
    meaning: false,
    example: false,
    exampleTranslation: false
  };

  const [formData, setFormData] = useState(initialFormState);
  const [formDataErr, setFormDataErr] = useState(initialFormStateErr);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormDataErr({
      ...formDataErr,
      [`${e.target.name}Err`]: false
    });
    
    for (const eachProp in formData) {
      if (formData[`${eachProp.substring(0, eachProp.length - 3)}`] === '') {
        setFormDataErr({
          ...formDataErr,
          [eachProp]: true
        });
      }
    }

    if (Object.values(formDataErr).every(itemVal => itemVal === false)) {  
      fetch(`http://localhost:3001/${formData.letterCategory}`, {
        method: 'POST',
        headers: {"Content-type": "application/json"},
        body: JSON.stringify({
          ...formData
        })
      })
        .then(() => history.push(`/${formData.letterCategory}`));
    }
  };

  return (
    <main>
      <Container>
        <Typography
          variant="h6"
          color="primary"
          component="h2"
          gutterBottom
        >
          Enter New Word
        </Typography>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <FormControl variant="standard" sx={{ minWidth: 150 }}>
            <InputLabel id="letter-category-label">Letter Category</InputLabel>
            <Select
              labelId="letter-category-label"
              id="letter-category"
              name="letterCategory"
              value={formData.letterCategory}
              onChange={handleChange}
              label="Letter Category"
              error={formDataErr.letterCategoryErr}
            >
              {allAlphabet().map((letter, i) => (
                <MenuItem key={i} value={letter}>{letter.toUpperCase()}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="standard"
            label="Word Name"
            name="wordName"
            fullWidth
            margin="normal"
            required
            error={formDataErr.wordNameErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="Image URL"
            name="imageUrl"
            fullWidth
            margin="normal"
            required
            error={formDataErr.imageUrlErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="Word Type"
            name="wordType"
            fullWidth
            margin="normal"
            required
            error={formDataErr.wordTypeErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="かな"
            name="kana"
            fullWidth
            margin="normal"
            required
            error={formDataErr.kanaErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="English"
            name="english"
            fullWidth
            margin="normal"
            required
            error={formDataErr.englishErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="Meaning"
            name="meaning"
            fullWidth
            margin="normal"
            required
            error={formDataErr.meaningErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="Example"
            name="example"
            fullWidth
            margin="normal"
            required
            error={formDataErr.exampleErr}
            onChange={handleChange}
          />
          <TextField
            variant="standard"
            label="Translation"
            name="exampleTranslation"
            fullWidth
            margin="normal"
            required
            error={formDataErr.exampleTranslationErr}
            onChange={handleChange}
          />
          
          <Button
            type="submit"
            color="primary"
            variant="contained"
            margin="normal"
            endIcon={<KeyboardArrowRightIcon />}
          >
            Submit
          </Button>
        </form>
      </Container>
    </main>
  )
}

export default DataEntry;
