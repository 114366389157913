import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material';

const AlphabetCard = ({ letter, bgColour }) => {
  return (
    <>
      <Card
        elevation={0}
      >
        <Avatar
          sx={{ bgcolor: bgColour }}
        >
          <Typography
            variant="h2"
          >
            {letter}
          </Typography>
        </Avatar>
      </Card>
    </>
  )
}

export default AlphabetCard;
