import './scss/index.scss';

import React from 'react';

import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import RightDrawer from '../RightDrawer';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'center',
  paddingTop: theme.spacing(.5),
  paddingBottom: theme.spacing(.5),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 64,
  },
}));

const Header = ({ children }) => {
  return (
    <>
      <header>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <StyledToolbar>
              <AutoStoriesIcon
                className="logo-icon"
                onClick={e => window.location.href='/'}
              />
              <Typography
                className="logo-text"
                variant="h1"
                noWrap
                component="div"
                onClick={e => window.location.href='/'}
              >
                Japanglish Dictionary
              </Typography>
              <RightDrawer />
            </StyledToolbar>
          </AppBar>
        </Box>
      </header>
      {children}
    </>
  );
}

export default Header;
