import { useEffect, useState } from 'react';

import { ref, onValue } from 'firebase/database';
import { db } from '../../utils/firebase';

import Layout from '../../components/Layout';
import WordTemplate from '../../components/WordTemplate';

import { accentedLetter, getColour } from '../../utils/util';

const WordDay = () => {
  const [allAlphabets, setAllAlphabets] = useState([]);
  const [words, setWords] = useState([]);
  let todayWord = [], chosenLetter = '', n = 0;
  
  // Get all available letters in db
  useEffect(() => {
    onValue(ref(db, '/'), (snapshot) => {      
      setAllAlphabets(Object.keys(snapshot.val()));
    })
  }, []);

  if (allAlphabets.length > 0) {
    // Get random n for the day
    let currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    currentDate = yyyy + mm + dd;
    n = (parseInt(yyyy) * parseInt(mm) * parseInt(dd)) % allAlphabets.length;

    // Choose letter based on n
    chosenLetter = allAlphabets[n];
  }
  
  // Get all words for the chosen letter
  useEffect(() => {
    chosenLetter.length > 0 &&
    onValue(ref(db, `${accentedLetter(chosenLetter)}`), (snapshot) => {
      setWords(Object.entries(snapshot.val()).map(wordArr => (
        { [wordArr[0]]: wordArr[1] }
      )));
    });
  }, [chosenLetter]);

  // Get the word of the day based on n of the day
  if (words.length > 0) {
    const k = n % words.length;
    todayWord.push(words[k]);
  }

  return (
    <Layout
      desLink={`/`}
      linkText="Home"
    >
      {words.length > 0 &&
        <WordTemplate
          wordObj={todayWord[0]}
          bgColour={getColour(Object.keys(todayWord[0])[0].charAt(0))}
        />
      }
    </Layout>
  )
}

export default WordDay;
