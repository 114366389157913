import './scss/index.scss';

import React from 'react';

import AddchartIcon from '@mui/icons-material/Addchart';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const RightDrawer = ({ children }) => {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Word of the Day', 'Resources', 'Disclaimer'].map((text, index) => (
          <ListItem
            button key={text}
            component="a"
            href={`/${text.toLowerCase().replaceAll(' ', '-')}`}
          >
            <ListItemIcon>
              {index === 0 ? <LightbulbOutlinedIcon /> : index === 1 ? <AddchartIcon /> : <AnnouncementIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            className="dot-menu-icon"
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={toggleDrawer(anchor, true)}
          >
            <MoreIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
          {children}
        </React.Fragment>
      ))}
    </>
  );
}

export default RightDrawer;
