import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import { capsFirst, getWordObj } from '../../utils/util';

const WordTemplate = ({ wordObj, bgColour }) => {
  const word = getWordObj(wordObj);

  return (
    <Card
      elevation={1}
      key={word.wordName}
      sx={{
        marginTop: '3em',
        marginBottom: '3em'
      }}
    >
      <CardMedia
        component="img"
        height="350"
        image={word.imageUrl}
        alt={capsFirst(word.english)}
      />
      <CardHeader
        avatar={
          <Avatar
            sx={{ 
              bgcolor: bgColour,
              width: 56,
              height: 56,
              fontSize: "1.75rem"
            }}
            className="letter-l"
          >
            {word.wordName.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={capsFirst(word.wordName)}
      />
      <CardContent>
        <Typography variant="h5">
          {word.kana}
        </Typography>
        <Typography
          variant="caption"
          color="#afafaf"
        >
          {word.wordType}
        </Typography>
        <Typography
          variant="body2"
          sx={{ margin: '.75em 0' }}
        >
          {capsFirst(word.english)}
        </Typography>
        <Typography
          variant="body2"
          color="#8f8f8f"
        >
          {word.meaning}
        </Typography>
        <Typography
          variant="h3"
          color="primary"
          sx={{
            fontSize: "1em",
            margin: "1em 0"
          }}
        >
          Example Sentence
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body2"
            >
              {word.example}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              color="#8f8f8f"
            >
              {word.exampleTranslation}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  )
}

export default WordTemplate;
