import React from 'react'
import { Link } from 'react-router-dom';

import { Container } from '@mui/material';
import Button from '@mui/material/Button';

const Layout = ({ desLink, linkText, children }) => {
  return (
    <main>
      <Container>
        {(desLink && linkText)
          ? <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={desLink}
            >
              {linkText}
            </Button>
          : null
        }
        {children}
      </Container>
    </main>
  )
}

export default Layout;
