import './scss/index.scss';

import { useHistory } from 'react-router-dom';

import Masonry from 'react-masonry-css';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { accentedLetter, getColour, capsFirst, getWordObj, truncateStr } from '../../utils/util';

const Collections = ({ wordsObj, setWord, setBgColour }) => {
  let history = useHistory();

  let words = [];
  wordsObj.map(wordObj => words.push(getWordObj(wordObj)));

  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1
  };

  let selectedWordObj = [];
  const bgColour = getColour(words[0].wordName.charAt(0));

  const handleOnClick = (wordName) => {
    selectedWordObj = wordsObj.filter(word => Object.keys(word).includes(wordName));
    setWord(selectedWordObj[0]);
    setBgColour(bgColour);
    history.push(`/${accentedLetter(wordName.charAt(0))}/${wordName}`);
  };

  return (
    <Masonry
      container="true"
      breakpointCols={breakpoints}
      spacing={3}
      className="collections-masonry-grid"
      columnClassName="collections-masonry-grid-column"
    >
      {words.map(word =>
        <Card
          sx={{ maxWidth: 345 }}
          elevation={1}
          key={word.wordName}
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: bgColour }} aria-label="recipe">
                {accentedLetter(word.wordName.charAt(0)).toUpperCase()}
              </Avatar>
            }
            title={capsFirst(word.wordName)}
          />
          <CardMedia
            component="img"
            height="194"
            image={word.imageUrl}
            alt={capsFirst(word.english)}
          />
          <CardContent>
            <Typography variant="h5">
              {word.kana}
            </Typography>
            <Typography
              variant="caption"
              color="#afafaf"
            >
              {word.wordType}
            </Typography>
            <Typography
              variant="body2"
              sx={{ margin: '.75em 0' }}
            >
              {capsFirst(word.english)}
            </Typography>
            <Typography
              variant="body2"
              color="#8f8f8f"
            >
              {truncateStr(word.meaning)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => handleOnClick(word.wordName)}
            >
              Learn More
            </Button>
          </CardActions>
        </Card>  
      )}
    </Masonry>
  )
}

export default Collections;
