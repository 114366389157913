import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';
// import { getAnalytics } from 'firebase/analytics';
// import wordsData from '../data/wordsData.json';

// Set the app configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "japanglish-dictionary.firebaseapp.com",
  databaseURL: "https://japanglish-dictionary-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "japanglish-dictionary",
  storageBucket: "japanglish-dictionary.appspot.com",
  messagingSenderId: "183655515496",
  appId: "1:183655515496:web:11a69243734aa736c702dd",
  measurementId: "G-7789RV992B"
};

const app = initializeApp(firebaseConfig);
// Get a reference to the database service
const db = getDatabase(app);

// export const analytics = getAnalytics(app);

// BASIC WRITE OPERATION
// set(ref(databaseInstance, theKeyAsPath) -> this ref will reference to the path in database, if none exists, will create, else will overwrite existing
// , {the data structure with the value to insert to database})
const createData = (letter, wordName, imageUrl, wordType, kana, english, meaning, example, exampleTranslation) => {
  set(ref(db, `${letter}/${wordName}`), {
    imageUrl: imageUrl,
    wordType: wordType,
    kana: kana,
    english: english,
    meaning: meaning,
    example: example,
    exampleTranslation: exampleTranslation
  })
  .then(() => console.log("Data saved successfully!"))
  .catch(err => console.log(err));
};

// // Test write
// Object.keys(wordsData).map(letter =>
//   Object.keys(wordsData[letter]).map(word =>
//     createData(
//       letter,
//       word,
//       wordsData[letter][word].imageUrl,
//       wordsData[letter][word].wordType,
//       wordsData[letter][word].kana,
//       wordsData[letter][word].english,
//       wordsData[letter][word].meaning,
//       wordsData[letter][word].example,
//       wordsData[letter][word].exampleTranslation
//     )
//   )
// );

export {
  db,
  createData
};
