import './scss/index.scss';

import Layout from '../../components/Layout';
import WordTemplate from '../../components/WordTemplate';

import { accentedLetter } from '../../utils/util';

const Word = ({ word, bgColour }) => {
  return (
    <Layout
      desLink={`/${accentedLetter(Object.keys(word)[0].charAt(0))}`}
      linkText="Back"
    >
       <WordTemplate
        wordObj={word}
        bgColour={bgColour}
      /> 
    </Layout>
  )
}

export default Word;
