import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const RefCard = ({ resource }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="194"
        image={resource.imgUrl}
        alt={resource.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {resource.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {resource.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          target="_blank"
          rel="noreferrer noopener"
          href={resource.refUrl}
        >
          Go to Link
        </Button>
      </CardActions>
    </Card>
  )
}

export default RefCard;
