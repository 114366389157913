// List of a-z
export const allAlphabet = () => {
  let alphabets = [];
  [...Array(26)].map((e, i) => alphabets.push(String.fromCharCode(i + 97)));
  return alphabets;

  // // Old hardcoded before firebase
  // const removeAlphabets = ['l', 'q', 'u', 'x', 'y'];
  // [...Array(26)].map((e, i) => alphabets.push(String.fromCharCode(i + 97)));
  // alphabets = alphabets.filter((char) => !removeAlphabets.includes(char));
  // return alphabets;
};

// Map accented letters and list of a-z
export const accentedLetter = (letter) => {
  switch(letter) {
    case 'ā':
      return 'a';
    case 'ī':
      return 'i';
    case 'ū':
      return 'u';
    case 'ē':
      return 'e';
    case 'ō':
      return 'o';
    default:
      return letter;
  }
};

// List of colours for alphabets
export const colourCode = (i) => {
  const colours = [
    '#ff5252', '#e040fb', '#536dfe', '#009688', '#ff8f00',
    '#4caf50', '#9575cd', '#ff4081', '#00bcd4', '#607d8b',
    '#aa00ff', '#a1887f', '#8d6e63', '#03a9f4', '#ffeb3b',
    '#e1bee7', '#40c4ff', '#ffcc80', '#ef9a9a', '#aeea00',
    '#ef5350', '#6200ea', '#4e342e', '#b0bec5', '#ffca28',
    '#ff80ab'
  ];
  return colours[i];
};

// Map colours with alphabets
export const getColour = (letter) => {
  return (colourCode(allAlphabet().indexOf(accentedLetter(letter))));
};

// Capitalise first letter of each word
export const capsFirst = (sentence) => {
  const words = sentence.split(" ");
  let capsWords = '';

  words.map((word) => (
    capsWords += word[0].toUpperCase() + word.substring(1) + " "
  ));

  return capsWords.trim();
};

// Truncate string
export const truncateStr = (inputStr, maxLength = 60) => {
  return inputStr.substring(0, maxLength).concat('...');
};

// Convert word object from db to match app structure
export const getWordObj = (wordObj) => {
  return Object.entries(wordObj).map(wordItem => (
    {
      wordName: wordItem[0],
      english: wordItem[1].english,
      example: wordItem[1].example,
      exampleTranslation: wordItem[1].exampleTranslation,
      imageUrl: wordItem[1].imageUrl,
      kana: wordItem[1].kana,
      meaning: wordItem[1].meaning,
      wordType: wordItem[1].wordType,
    }
  ))[0];
};
