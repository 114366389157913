import './scss/index.scss';

import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div>
        <span>Japanglish Dictionary</span>
        <span>Copyright &copy; {new Date().getFullYear()}. Jessica Gozali.</span>
      </div>
    </footer>
  );
}

export default Footer
