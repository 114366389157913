import React from 'react';

import { Container, Typography } from '@mui/material';

const Disclaimer = () => {
  return (
    <main>
      <Container>
        <div>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{ fontSize: "2em", marginBottom: "1em" }}
          >
            Disclaimer
          </Typography>
          <Typography
            variant="body1"
            color="#8f8f8f"
          >
            This is a disclaimer about this Japanglish Dictionary app content and terms of use. This is a personal project of Jessica Gozali which purpose is to learn React as a beginner. Please hold your rage if you find the code is ugly and unsophisticated. This app does not worth the energy used to get angry. This is a practice app of an amateur.
          </Typography>
          <Container>
            <Typography
              variant="h3"
              sx={{
                fontSize: "1.35em",
                fontWeight: 300,
                margin: "2em 0 1em"
              }}
            >
              About The Content
            </Typography>
            <Typography
              variant="body1"
              color="#8f8f8f"
              sx={{ marginBottom: "1em" }}
            >
              The content used in this app is not guaranteed to be accurate or factual. It is meant to share some fun information about Japanese-English words that can be heard when visiting Japan. If you find the information in this app is inaccurate or misleading, please close your eyes and pretend it never exists.
            </Typography>
            <Typography
              variant="body1"
              color="#8f8f8f"
            >
              All images used in this app are sourced from Pexels and are under Pexel's license, which is free to use for personal or commercial purpose.
            </Typography>
          </Container>
          <Container>
            <Typography
              variant="h3"
              sx={{
                fontSize: "1.35em",
                fontWeight: 300,
                margin: "2em 0 1em"
              }}
            >
              Term of Use
            </Typography>
            <Typography
              variant="body1"
              color="#8f8f8f"
              sx={{ marginBottom: "1em" }}
            >
              By using this app, you are agree to stay calm and give constructive feedback to the project owner regarding this app. Your rant is important to ensure much better quality apps are built in the future.
            </Typography>
            <Typography
              variant="body1"
              color="#8f8f8f"
            >
              Due to the nature of the content being very casually collected and compiled without any professional or expert involvement, please refrain from using this app as an educational app to teach the humanity.
            </Typography>
          </Container>
        </div>
      </Container>
    </main>
  )
}

export default Disclaimer;
