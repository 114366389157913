import './scss/index.scss';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { db } from '../../utils/firebase';

import { Container, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';

import AlphabetCard from '../../components/AlphabetCard';
import Layout from '../../components/Layout';

import { getColour } from '../../utils/util';

const Home = () => {
  const [allAlphabets, setAllAlphabets] = useState([]);

  // BASIC READ OPERATION
  // Use onValue(databaseInstance, thePath) which listens to data from the path, will return the new value when data change
  // const readData = (letter = '', word = '') => {
  //   // If all arguments are empty string, get all available letters
  //   if (letter === '' && word === '') {
  //     onValue(ref(db, '/'), (snapshot) => {
  //       setAllAlphabets(snapshot.val());
  //     });
  //   }

    // If letter is not empty but word empty, get all words collection for that letter


    // If letter and word not empty, get word content
  // };

  // // Test read using get, not efficient if reading is needed multiple time, this only suitable if reading happens once and won't listen to data onChange
  // const wordName = "aisukurīmu";
  // get(child(ref(database), `/${wordName}`)).then((snapshot) => {
  //   if (snapshot.exists()) {
  //     console.log(snapshot.val());
  //   } else {
  //     console.log("No data available");
  //   }
  // }).catch((error) => {
  //   console.error(error);
  // });

  // readData();
  // console.log(allAlphabets);
  
  useEffect(() => {
    onValue(ref(db, '/'), (snapshot) => {      
      setAllAlphabets(Object.keys(snapshot.val()));
    })
  }, []);

  return (
    <Layout>
      <Typography
        variant="h2"
        textAlign="center"
        sx={{ fontSize: "2em", marginBottom: "1em" }}
      >
        Pick an alphabet!
      </Typography>
      <div>
        <Container>
          <Grid container>
            {allAlphabets.length > 0 && allAlphabets.map((letter, i) => (
              <Grid
                className="alphabet-grid-item"
                key={i}
                item
                xs={4}
                sm={2}
                component={Link}
                to={`/${letter}`}
              >
                <AlphabetCard
                  letter={letter}
                  bgColour={getColour(letter)}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export default Home;
