import { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Home from './pages/Home/Home';
import Resources from './pages/Resources/Resources';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import DataEntry from './pages/DataEntry/DataEntry';
import Word from './pages/Word/Word';
import WordCollections from './pages/WordCollections/WordCollections';
import WordDay from './pages/WordDay/WordDay';

import Footer from './components/Footer';
import Header from './components/Header';

// import wordsData from './data/wordsData.json';
// import { createData } from './utils/firebase';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
    main: '#0A8CE3',
    },
    secondary: {
    main: '#ffa523',
    contrastText: 'rgba(0,0,0,0.87)',
    },
  },
  typography: {
    fontFamily: [
      '"Noto Sans Display"',
      '"Noto Sans JP"',
      'sans-serif',
    ].join(','),
  },
});

// // Test write
// Object.keys(wordsData).map(letter =>
//   Object.keys(wordsData[letter]).map(word =>
//     createData(
//       letter,
//       word,
//       wordsData[letter][word].imageUrl,
//       wordsData[letter][word].wordType,
//       wordsData[letter][word].kana,
//       wordsData[letter][word].english,
//       wordsData[letter][word].meaning,
//       wordsData[letter][word].example,
//       wordsData[letter][word].exampleTranslation
//     )
//   )
// );

function App() {
  const [word, setWord] = useState({});
  const [bgColour, setBgColour] = useState({});

  return (
    <ThemeProvider theme={theme}>
      <Header>
        <Router>
          <Switch>
            <Route exact path="/resources" component={Resources} />
            <Route exact path="/disclaimer" component={Disclaimer} />
            <Route exact path="/word-of-the-day" component={WordDay} />
            <Route exact path="/data-entry" component={DataEntry} />
            <Route exact path="/:letter">
              <WordCollections setWord={setWord} setBgColour={setBgColour} />
            </Route>
            <Route exact path="/:letter/:word">
              <Word word={word} bgColour={bgColour} />
            </Route>
            <Route exact path="/" component={Home} />
          </Switch>
        </Router>
      </Header>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
