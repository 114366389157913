import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ref, onValue } from 'firebase/database';
import { db } from '../../utils/firebase';

import Layout from '../../components/Layout';
import Collections from '../../components/Collections';

import { accentedLetter } from '../../utils/util';

const WordCollections = (props) => {
  const { letter } = useParams();
  const chosenLetter = letter;
  const [words, setWords] = useState([]);

  useEffect(() => {
    chosenLetter.length > 0 &&
    onValue(ref(db, `${accentedLetter(chosenLetter)}`), (snapshot) => {
      setWords(Object.entries(snapshot.val()).map(wordArr => (
        { [wordArr[0]]: wordArr[1] }
      )));
    });
  }, [chosenLetter]);
  
  return (
    <Layout
      desLink='/'
      linkText="Back"
    >
      {
        words.length > 0 && <Collections wordsObj={words} setWord={props.setWord} setBgColour={props.setBgColour} />
      }
    </Layout>
  )
}

export default WordCollections;
